
import React from 'react'
import './section.css'
import { Container, Row, Col } from 'reactstrap'
import { useEffect, useRef } from 'react';


function Section() {

  const companyRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('active');
        } else {
          entry.target.classList.remove('active');
        }
      });
    });

    observer.observe(companyRef.current);

    return () => observer.unobserve(companyRef.current);
  }, []);

  const handleLabelClick = (e, radioId) => {
    e.preventDefault(); // Prevent default behavior (including scrolling)
    const radioElement = document.getElementById(radioId);
    if (radioElement) {
      radioElement.checked = true; // Manually set the radio button state
    }
  };
  return (
    <section className='section-info' ref={companyRef} id='section-info'>
 
      <div className='slide'>
  <div data-am-gallery>
    
    <input type="radio" name="gallery" id="img-1" checked />
    <input type="radio" name="gallery" id="img-2" />
    <input type="radio" name="gallery" id="img-3" />
    <input type="radio" name="gallery" id="img-4" />
    <input type="radio" name="gallery" id="img-5" />

    <div class="images">
      <div class="image" style={{ backgroundImage: 'url(/assets/images/jason.jpg)' }}>
      <label className="button back" onClick={(e) => handleLabelClick(e, 'img-5')}>
            <i className="material-symbols-outlined">arrow_back_ios</i>
      </label>
        <label className="button forward" onClick={(e) => handleLabelClick(e, 'img-2')}     >
          <i class="material-symbols-outlined">arrow_forward_ios</i>
          </label>
        <div class="lb_img_description">BigUp pour Avancer Ensemble!</div>
      </div>

      <div class="image" style={{ backgroundImage: 'url(/assets/images/jason.jpg)' }}>
  <label className="button back" onClick={(e) => handleLabelClick(e, 'img-1')}>
    <i className="material-symbols-outlined">arrow_back_ios</i>
  </label>
  <label className="button forward" onClick={(e) => handleLabelClick(e, 'img-3')}>
    <i className="material-symbols-outlined">arrow_forward_ios</i>
  </label>
  <div class="lb_img_description">un Objectif Unique</div>
</div>

<div class="image" style={{ backgroundImage: 'url(/assets/images/jason.jpg)' }}>
  <label className="button back" onClick={(e) => handleLabelClick(e, 'img-2')}>
    <i className="material-symbols-outlined">arrow_back_ios</i>
  </label>
  <label className="button forward" onClick={(e) => handleLabelClick(e, 'img-4')}>
    <i className="material-symbols-outlined">arrow_forward_ios</i>
  </label>
  <div class="lb_img_description">Des Valeurs Partagées</div>
</div>

<div class="image" style={{ backgroundImage: 'url(/assets/images/jason.jpg)' }}>
  <label className="button back" onClick={(e) => handleLabelClick(e, 'img-3')}>
    <i className="material-symbols-outlined">arrow_back_ios</i>
  </label>
  <label className="button forward" onClick={(e) => handleLabelClick(e, 'img-5')}>
    <i className="material-symbols-outlined">arrow_forward_ios</i>
  </label>
  <div class="lb_img_description">BigUp pour Avancer Ensemble!</div>
</div>

<div class="image" style={{ backgroundImage: 'url(/assets/images/jason.jpg)' }}>
  <label className="button back" onClick={(e) => handleLabelClick(e, 'img-4')}>
    <i className="material-symbols-outlined">arrow_back_ios</i>
  </label>
  <label className="button forward" onClick={(e) => handleLabelClick(e, 'img-1')}>
    <i className="material-symbols-outlined">arrow_forward_ios</i>
  </label>
  <div class="lb_img_description">Des Valeurs Partagées</div>
</div>
    </div>

  </div>
</div>
    </section>
  )
}

export default Section
