import React, { Fragment } from 'react'
import Header from './Header'

function Home() {
  return (


    <Fragment>
    <Header/>


    </Fragment>

  )
}

export default Home
